@tailwind base;
@tailwind components;
@tailwind utilities;

// Additional styles
@import 'css/additional-styles/utility-patterns.scss';
@import 'css/additional-styles/range-slider.scss';
@import 'css/additional-styles/toggle-switch.scss';
@import 'css/additional-styles/theme.scss';

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
    .rtl {
        direction: rtl;
    }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
    display: none;
}

// AOS styles
$aos-distance: 10px;
@import 'node_modules/aos/src/sass/aos.scss';
