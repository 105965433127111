.article {
    font-family: "微软雅黑 Light", "Inter", "sans-serif";
    font-size: 1em;
    line-height: 30px;
}

.article ul li {
    list-style: disc;
}

.article ol li {
    list-style: decimal;
}